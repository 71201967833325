<template>
  <div>
    <van-nav-bar
        title="充值订单"
        left-text="返回"
        right-text="充值"
        left-arrow
        fixed
        @click-left="$router.back()"
        @click-right="() => { reset();showPayForm = true; }"
    />
    <page-container class="deposit-order-list-container">
      <!-- 下拉刷新 -->
      <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
          style="position: relative;z-index: 1"
      >
        <template #loading>
          {{ pagination.pageNum < 2 ? '' : '加载中...' }}
        </template>

        <!-- 更多列表加载 -->
        <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="finishedTextRender()"
            @load="onLoad"
        >
          <div
              class="paging-list-item"
              v-for="item of dataList"
          >
            <div class="title-info">
              <p>￥{{ item.amount }}</p>
              <span>{{ statusMap[item.status] }}</span>
            </div>

            <div class="detail-item">
              <label>订单号</label>
              <span>{{ item.orderNo }}</span>
            </div>
            <div class="detail-item">
              <label>创建时间</label>
              <span>{{ item.createTime }}</span>
            </div>

            <div
                v-if="item.status === '02'"
                style="padding: 1em;text-align: right"
            >
              <van-button
                  type="primary"
                  size="small"
                  @click="shareMoney(item.orderNo)"
              >
                &nbsp;&nbsp;分账&nbsp;&nbsp;
              </van-button>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </page-container>

    <van-overlay v-if="showPayForm" show>
      <div>
        <van-form
            label-width="5.5em"
            input-align="right"
            @submit="onSubmit"
        >
          <van-cell-group
              style="padding: 1em"
              inset
          >
            <p style="padding: 0 0 .05rem 0;text-align: center;font-size: .05rem;">充值</p>

            <van-field
                v-model="form.amount"
                name="amount"
                label="金额" required
                type="textarea"
                rows="1"
                :autosize="true"
                placeholder="请填写金额"
                :rules="[
                    { required: true, message: '请输入正确的金额' },
                    { validator: () => {
                      return parseFloat(form.amount) > 0 && parseFloat(form.amount) < 10000;
                    }, message: '金额必须大于0元，且小于10000元'}
                ]"
            />

            <p style="margin: .02rem 0;padding: .02rem;font-size: .042rem;color: #ff7510;border: 1px solid #ff7c1b;border-radius: .02rem">
              提示:请输入零碎金额。例如：2234.51
            </p>

            <!--            <van-field-->
            <!--                name="payType"-->
            <!--                label="支付方式" required-->
            <!--                :rules="[{ required: true, message: '请选择支付方式' }]"-->
            <!--            >-->
            <!--              <template #input>-->
            <!--                <van-radio-group v-model="form.payType">-->
            <!--                  <van-radio name="02">支付宝支付</van-radio>-->
            <!--                  <van-radio name="01" style="margin-top: 1em">微信支付</van-radio>-->
            <!--                </van-radio-group>-->
            <!--              </template>-->
            <!--            </van-field>-->
            <div class="btn-container">
              <van-button round @click="showPayForm = false">取消</van-button>
              <van-button round type="primary" native-type="submit">支付</van-button>
            </div>

          </van-cell-group>

        </van-form>
      </div>
    </van-overlay>

  </div>
</template>

<script>
import { getDepositOrderList, depositOrderShare, depositOrderCreate } from '@/api/depositOrder';
import { Dialog, Toast } from 'vant';
import { objectGetter } from '@/util';
import { depositOrderWxPay } from '@/util/wxJsPay';

const defaultForm = () => ({
  amount: '',
  payType: '01',
});


export default {
  name: 'depositOrderList',
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      dataList: [],
      pagination: {
        // 分页参数
        pageNum: 0,
        pageSize: 10,
        total: 0,
      },

      form: defaultForm(),
      showPayForm: false,
    };
  },
  computed: {
    statusMap() {
      return {
        '01': '未支付',
        '02': '已支付',
        '03': '已到账',
      };
    },

  },
  methods: {
    onLoad() {
      this.pagination.pageNum++;
      let {
        searchForm,
        pagination: { pageNum: current, pageSize: size },
      } = this;

      return getDepositOrderList({
        ...searchForm,
        current,
        size,
      }).then(data => {
        this.dataPageLoadingSuccess();
        let { records: dataList = [], total = 0 } = data || {};
        this.dataList = [...this.dataList, ...dataList];
        this.pagination.total = total || 0;
        if (this.dataList.length >= this.pagination.total || !dataList.length)
          this.finished = true;
      });
    },
    onRefresh() {
      // 清空列表数据
      this.dataList = [];
      this.pagination.pageNum = 0;
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      return this.onLoad();
    },
    dataPageLoadingSuccess() {
      this.loading = false;
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
    },
    finishedTextRender() {
      let { loading, finished, dataList } = this;
      if (!loading && finished) {
        return dataList.length ? '没有更多了' : '暂无相关数据';
      }
    },


    shareMoney(orderNo) {
      Dialog.confirm({
        title: `是否分账？`,
        // message: '弹窗内容',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            depositOrderShare({ orderNo }).then(res => {
              if (res === true) {
                done();
                this.onRefresh();
                this.$router.back();
              } else {
                done();
              }
            });
          } else {
            done();
          }
        },
      }).catch(() => {
      });
    },

    reset() {
      this.form = defaultForm();
    },
    onSubmit() {
      let formData = { ...this.form };
      const randomMoney = '.' + Math.random().toFixed(2).split('.')[1];
      const [m, d] = (formData.amount + '').split('.');
      let amount = +d ? formData.amount : (m + randomMoney);

      depositOrderCreate({ ...this.form, amount }).then(res => {

        depositOrderWxPay(res).then(res => {
          if (res) {
            Toast('充值成功');
            this.onRefresh();
          }
        });
      });
    },

  },
};
</script>

<style lang="less" scoped>
.deposit-order-list-container {
  top: @nav-bar-height;
}

.paging-list-item {
  margin: 10px;
  padding: 0 0 10px 0;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;

  .title-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 10px 0;
    padding: 10px;
    border-bottom: 1px dashed #dedede;

    & > p {
      margin-bottom: 4px;
      padding-left: 8px;
      font-size: 18px;
      color: red;
    }

    & > span {
      font-size: 14px;
      color: #999;
    }
  }

  .detail-item {
    display: flex;
    padding: 0 14px;
    line-height: 1.4;

    & > label {
      flex-shrink: 0;
      display: inline-block;
      width: 5em;
      text-align: right;
      color: #999;

      &:after {
        content: '：';
      }
    }

    & > span {
      flex: 1;
      padding-left: .5em;
      color: #666;
    }
  }
}

.van-overlay {
  align-items: center;

  & > div {
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 38.2%;
    transform: translateY(-50%);
  }
}

.btn-container {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px 10px 0 10px;
  width: 100%;

  & > button {
    width: 45%;
  }
}

</style>
