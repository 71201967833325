import request from '@/plugins/axios';

export function getDepositOrderList(query) {
  return request({
    url: 'shop-mobile/depositOrder/page',
    method: 'get',
    params: query
  });
}

// 充值订单分账
export function depositOrderShare(obj) {
  return request({
    url: `shop-mobile/depositOrder/share?orderNo=${obj.orderNo}`,
    method: 'post',
    data: obj
  });
}

// 充值订单创建支付
export function depositOrderCreate(obj) {
  return request({
    url: `shop-mobile/depositOrder/create`,
    method: 'put',
    data: obj
  });
}
